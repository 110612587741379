import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/lifestyle';

export const Root = styled.section`
  max-width: 870px;
  margin: 0 auto 20px;

  @media ${config.media.tabletOnly} {
    max-width: 633px;
  }

  @media ${config.media.mobile} {
    padding: 0 20px;
  }
`;

export const Header = styled.h2`
  font-family: ${config.fonts.serif};
  font-size: 22px;
  color: ${config.colors.text};
  text-align: center;
  margin: 50px 0 60px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`;

export const Article = styled.article`
  @media ${config.media.desktopOnly} {
    flex: 1 1 283px;
    margin-bottom: 60px;

    &:nth-child(2n+1) {
      margin-right: 70px;
    }
  }

  @media ${config.media.tabletOnly} {
    flex: 1 1 283px
    margin-bottom: 60px;

    &:nth-child(2n+1) {
      margin-right: 66px;
    }
  }

  @media ${config.media.mobile} {
    flex: 1 1 100%;
    margin-bottom: 80px;
  }
`;

export const Title = styled.h3`
  font-family: ${config.fonts.serif};
  font-size: 14px;
  color: ${config.colors.main};
  font-weight: bold;
  margin: 0 0 10px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  text-align: center;
`;

export const Figure = styled.figure`
  margin: 0;

  & img {
    max-width: 100%;

    @media ${config.media.desktopOnly} {
      object-fit: cover;
      min-height: 300px;
    }
  }
`;

export const Figcaption = styled.figcaption`
  font-family: ${config.fonts.sansSerif};
  font-size: 14px;
  color: ${config.colors.text};
  line-height: 18px;
  margin: 22px 0 0;
  padding: 0 50px 0 40px;

  @media ${config.media.tabletOnly} {
    padding-left: 32px;
    padding-right: 0;
  }

  @media ${config.media.mobile} {
    padding: 0;
  }
`;

export const Section = ({title, image, text}) => (
  <Article>
    <Title>{title}</Title>
    <Figure>
      {image ? <img alt={title} title={title} src={image}/> : null}
      <Figcaption dangerouslySetInnerHTML={{__html: text}}/>
    </Figure>
  </Article>
);

export const InnerSections = ({tr}) => (
  <Root>
    <Header>{tr('innerSectionsTitle')}</Header>

    <Container>
      {tr('innerSections').map((article, index) => <Section {...article} key={index}/>)}
    </Container>
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(InnerSections);
