import React from "react";
import styled from "styled-components";
import T from "prop-types";
import {
  compose,
  lifecycle,
  withHandlers,
  withStateHandlers,
  setPropTypes,
  onlyUpdateForPropTypes,
} from "recompose";
import * as HOC from "@p14/HOC";
import config from "@p14/config";

export const Root = styled.div`
  width: 100%;
  height: 660px;
  overflow: hidden;
  position: relative;

  @media ${config.media.tablet} {
    height: 500px;
  }
`;

export const Container = styled.div`
  white-space: nowrap;
  font-size: 0;
  transition: transform 0.3s ease 0s;
  will-change: transform;
  height: 100%;
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-image: url(${(props) => props.slide});
  background-size: cover;
  background-position: center center;
`;

export const Pagination = styled.nav`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  height: 6px;
  text-align: center;
`;

export const PaginationItem = styled.button`
  padding: 0;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin: 0 12px;
  border: none;
  outline: none;
  background-color: ${(props) => (props.isActive ? "#b48b72" : "#ddd")};
  vertical-align: top;
  transition: background-color 0.3s ease 0s;

  &:hover {
    background-color: #b48b72;
  }
`;

export const Slider = ({
  slides,
  followedX,
  activeIndex,
  onActiveIndexChange,
}) => (
  <Root>
    <Container
      id="lifestyle-slider"
      isDragging={Boolean(followedX)}
      style={{
        transform: `translateX(${activeIndex * 100 * -1 + followedX * -1}%)`,
        transition: followedX ? "none" : "",
      }}
    >
      {slides.map((slide, index) =>
        slide.image.childImageSharp ? (
          <Slide key={slide.id} slide={slide.image.childImageSharp.fixed.src} />
        ) : null
      )}
    </Container>

    <Pagination>
      {slides.map((slide, index) => (
        <PaginationItem
          isActive={activeIndex === index}
          key={index}
          onClick={() => onActiveIndexChange(index)}
        />
      ))}
    </Pagination>
  </Root>
);

export const enhance = compose(
  withStateHandlers(
    { activeIndex: 0 },
    {
      onNext:
        ({ activeIndex }, { slides }) =>
        () => ({
          activeIndex:
            activeIndex + 1 > slides.length - 1 ? 0 : activeIndex + 1,
        }),
      onPrev:
        ({ activeIndex }, { slides }) =>
        () => ({
          activeIndex:
            activeIndex - 1 < 0 ? slides.length - 1 : activeIndex - 1,
        }),
      onActiveIndexChange: () => (activeIndex) => ({ activeIndex }),
    }
  ),
  withHandlers({
    onFollowTouchEnd: (props) => (followedX) =>
      followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null,
  }),
  lifecycle({
    componentDidMount() {
      this.interval = setInterval(this.props.onNext, 5000);
    },
    componentWillUnmount() {
      this.interval = clearInterval(this.interval);
    },
  }),
  HOC.withTouch("lifestyle-slider"),
  onlyUpdateForPropTypes,
  setPropTypes({
    followedX: T.number.isRequired,
    slides: T.array.isRequired,
    activeIndex: T.number.isRequired,
    onActiveIndexChange: T.func.isRequired,
  })
);

export default enhance(Slider);
