import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/lifestyle';

export const Root = styled.section`
  margin: 0 0 80px;

  @media ${config.media.mobile} {
    padding: 0 20px;
  }
`;

export const Title = styled.h2`
  margin: 0 0 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: ${config.fonts.serif};
  font-size: 22px;
  color: ${config.colors.text};
  letter-spacing: 1px;
`;

export const Figure = styled.figure`
  background-color: #d8d8d8;
  height: 600px;
  margin: 0;

  @media ${config.media.mobile} {
    margin: 0 -20px;
    height: 320px;
  }
`;

export const FullScreenImage = ({tr}) => (
  <Root>
    <Title>{tr('secondTitle')}</Title>
    <Figure/>
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(FullScreenImage);
