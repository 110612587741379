export default {
  ru: {
    quote: 'Неформальная обстановка в лобби задумана как продолжение жилого пространства, разделенное на уютные зоны с диванами. Насыщенные тона стен и пола оттенены темным деревом, чтобы создать располагающую атмосферу – здесь приятно просто провести время.',
    quoteCaptions: ['Andrew Macken', 'Hesse Macken studio'],
    outerSectionsTitle: 'Дом почти невидим с улицы и повернут к пироговской самой узкой стороной. здесь ваша личная Москва: с небольшим частным садом, каминами и библиотекой.',
    outerSections: [{
      title: 'Ночная жизнь',
      image: '/static/lifestyle/nightlife.jpeg',
      text: 'Рестораны, бары, открытые веранды и&nbsp;кинопоказы – куда бы вы ни собирались сегодня, дойти можно будет пешком. Гид&nbsp;по&nbsp;лучшим и любимым местам мы&nbsp;собрали здесь.',
    }, {
      title: 'свежие продукты',
      image: '/static/lifestyle/freshfood.jpg',
      text: 'На Пироговскую, 14 можно заказать доставку любых продуктов и блюд, от&nbsp;свежей клубники и фермерского сыра до&nbsp;пхали и хумуса, с Усачевского рынка',
    }, {
      title: 'Фитнес зал',
      size: 'xl',
      image: '/static/lifestyle/gym.jpg',
      text: 'Полностью оснащенный тренажерами TECHNOGYM, открыт только для жителей дома и работает бесплатно. Для персональных занятий мы приглашаем лучших тренеров ProTrener.',
    }],
    innerSectionsTitle: 'Дом почти невидим с улицы и повернут к пироговской самой узкой стороной. здесь ваша личная Москва: с небольшим частным садом, каминами и библиотекой.',
    innerSections: [{
      title: 'Консьерж',
      image: '/static/lifestyle/small-1.jpg',
      text: 'Консьерж примет для вас посылки в любое время суток, а если вам доставили продукты в ваше отсутствие – они подождут в холодильнике. Билеты в театр, такси в аэропорт – это тоже к нему.',
    }, {
      title: 'Библиотека',
      image: '/static/lifestyle/small-2.jpg',
      text: 'Здесь можно выпить кофе у камина с&nbsp;гостем, полистать книги и альбомы и&nbsp;познакомиться с соседями – ощущение дома начинается уже в лобби.',
    }, {
      title: 'Безопасность',
      // image: '/static/lifestyle/small-3.jpg',
      text: 'От противодымной вентиляции до резервной системы обеспечения горячей водой, от автоматического регулирования отопления до охранной сигнализации – все вещи, о которых не хочется думать, уже&nbsp;продуманы.',
    }, {
      title: 'Комната хранения',
      // image: '/static/lifestyle/small-4.jpg',
      text: 'О вашем велосипеде, детской коляске и&nbsp;гироскутере мы тоже позаботимся, им&nbsp;нечего делать в апартаментах.',
    }],
    secondTitle: 'Ландшафтный дизайн',
  },

  en: {
    quote: 'Неформальная обстановка в лобби задумана как продолжение жилого пространства, разделенное на уютные зоны с диванами. Насыщенные тона стен и пола оттенены темным деревом, чтобы создать располагающую атмосферу – здесь приятно просто провести время.',
    quoteCaptions: ['Andrew Macken', 'Hesse Macken studio'],
    outerSectionsTitle: 'Дом почти невидим с улицы и повернут к пироговской самой узкой стороной. здесь ваша личная Москва: с небольшим частным садом, каминами и библиотекой.',
    outerSections: [{
      title: 'Ночная жизнь',
      image: '/static/lifestyle/nightlife.jpeg',
      text: 'Рестораны, бары, открытые веранды и кинопоказы – куда бы вы ни собирались сегодня, дойти можно будет пешком. Гид по лучшим и любимым местам мы собрали здесь.',
    }, {
      title: 'свежие продукты',
      image: '/static/lifestyle/freshfood.jpg',
      text: 'На Пироговскую, 14 можно заказать доставку любых продуктов и блюд, от свежей клубники и фермерского сыра до пхали и хумуса, с Усачевского рынка',
    }, {
      title: 'Фитнес зал',
      size: 'xl',
      image: '/static/lifestyle/gym.jpg',
      text: 'Полностью оснащенный тренажерами TECHNOGYM, открыт только для жителей дома и работает бесплатно. Для персональных занятий мы приглашаем лучших тренеров ProTrener.',
    }],
    innerSectionsTitle: 'Дом почти невидим с улицы и повернут к пироговской самой узкой стороной. здесь ваша личная Москва: с небольшим частным садом, каминами и библиотекой.',
    innerSections: [{
      title: 'Консьерж',
      image: '/static/section-2.jpg',
      text: 'Консьерж примет для вас посылки в любое время суток, а если вам доставили продукты в ваше отсутствие – они подождут в холодильнике. Билеты в театр, такси в аэропорт – это тоже к нему.',
    }, {
      title: 'Библиотека',
      image: '/static/section-2.jpg',
      text: 'Здесь можно выпить кофе у камина с гостем, полистать книги и альбомы и познакомиться с соседями – ощущение дома начинается уже в лобби.',
    }, {
      title: 'Лифт',
      image: '/static/section-2.jpg',
      text: 'От противодымной вентиляции до резервной системы обеспечения горячей водой, от автоматического регулирования отопления до охранной сигнализации – все вещи, о которых не хочется думать, уже продуманы.',
    }, {
      title: 'Комната хранения',
      image: '/static/section-2.jpg',
      text: 'О вашем велосипеде, детской коляске и гироскутере мы тоже позаботимся, им нечего делать в апартаментах.',
    }],
    secondTitle: 'Ландшафтный дизайн',
  },
};
