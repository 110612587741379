import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/lifestyle';

export const Root = styled.section`
  max-width: 870px;
  margin: 0 auto 20px;

  @media ${config.media.tabletOnly} {
    max-width: 690px;
  }

  @media ${config.media.mobile} {
    padding: 0 20px;
  }
`;

export const Header = styled.h2`
  font-family: ${config.fonts.serif};
  font-size: 22px;
  color: ${config.colors.text};
  text-align: center;
  margin: 50px 0 60px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Article = styled.article`
  @media ${config.media.desktopOnly} {
    flex: 0 0 ${props => props.size === 'xl' ? '790px' : '400px'};
    margin-bottom: 60px;
    margin-left: ${props => props.size === 'xl' ? '40px' : 0};

    &:nth-child(2n+1) {
      margin-right: 70px;
    }
  }

  @media ${config.media.tabletOnly} {
    flex: 0 0 ${props => props.size === 'xl' ? '690px' : '283px'};
    margin-bottom: 60px;

    &:nth-child(2n+1) {
      margin-right: ${props => props.size === 'xl' ? 'auto' : '66px'};
    }
  }

  @media ${config.media.mobile} {
    flex: 1 1 100%;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h3`
  font-family: ${config.fonts.serif};
  font-size: ${props => props.size === 'xl' ? '22px' : '14px'};
  color: ${config.colors.main};
  font-weight: bold;
  margin: 0 0 ${props => props.size === 'xl' ? '20px' : '10px'};
  letter-spacing: 0.12px;
  text-transform: uppercase;
  text-align: center;
`;

export const Figure = styled.figure`
  margin: 0;

  & img {
    max-width: 100%;
    width: 100%;
  }

  @media ${config.media.mobile} {
    margin: ${props => props.size === 'xl' ? '0 -20px' : 0};
  }
`;

export const Figcaption = styled.figcaption`
  font-family: ${config.fonts.sansSerif};
  font-size: 14px;
  color: ${config.colors.text};
  line-height: 18px;
  margin: 22px auto 0;
  padding: ${props => props.size === 'xl' ? '0' : '0 50px 0 40px'};
  max-width: ${props => props.size === 'xl' ? '400px' : '100%'};
  text-align: ${props => props.size === 'xl' ? 'center' : 'left'};

  @media ${config.media.tabletOnly} {
    padding-right: 0;
  }

  @media ${config.media.mobile} {
    padding: ${props => props.size === 'xl' ? '0 20px' : 0};
  }
`;

export const Section = ({title, image, text, size}) => (
  <Article size={size}>
    <Title size={size}>{title}</Title>
    <Figure size={size}>
      <img alt={title} src={image}/>
      <Figcaption size={size} dangerouslySetInnerHTML={{__html: text}}/>
    </Figure>
  </Article>
);

export const OuterSections = ({tr}) => (
  <Root>
    <Header>{tr('outerSectionsTitle')}</Header>

    <Container style={{display: 'block'}}>
      {tr('outerSections').slice(2).map((article, index) => <Section {...article} size="xl" key={index}/>)}
    </Container>

    <Container>
      {tr('outerSections').slice(0, 2).map((article, index) => <Section {...article} key={index}/>)}
    </Container>
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(OuterSections);
