import {graphql} from 'gatsby';

import Lifestyle from '../screens/lifestyle';
export default Lifestyle;

export const query = graphql`
  query {
    allStrapiLifestyleslides {
      edges {
        node {
          id,
          image {
            childImageSharp {
              fixed(width: 1730, height: 660) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
